import $ from "jquery";

$(".menu-toggler").on('click', function () {
	$(this).toggleClass("menu-toggler--opened");
	$(".header-nav-menu").toggleClass("menu--opened");
	$(".handheld-header__nav-wrapper").toggleClass("menu--opened");
	$(".site-header").toggleClass("menu--opened");
	$('.header__nav-menu').toggleClass("active");
});

$('.header__nav a[href="#"]').on('click', function (e) {
	e.preventDefault();
});

if (window.matchMedia('(max-width: 1199.5px)').matches) {
	$("#mobile-menu .menu-item-has-children").append('<button class="toggle-sub-menu"></button>');

	$("#mobile-menu .toggle-sub-menu").on('click', function (e) {
		$(this).parent().toggleClass("menu-item--opened");
	});
}

$(window).on('resize', function() {
	if(window.matchMedia('(max-width: 1240.5px)').matches && $('.toggle-sub-menu').length === 0) {
		$("#mobile-menu .menu-item-has-children").append('<button class="toggle-sub-menu"></button>');
		$(".toggle-sub-menu").on('click', function () {
			$(this).parent().toggleClass("menu-item--opened");
		});
	}
})

$('#mobile-menu > li > a').each((index, link) => {
	link.style.setProperty('--secondary-menu-item-index', index + 1);
});
