class SiteHeader {
    constructor($el, stickyOffset) {
        this.$el = $el;

        this.isSticky = false;
        this.isTicking = false;
        this.stickyOffset = stickyOffset;
        this.lastScrollPosition = window.pageYOffset;
        this.updateFn = this._update.bind(this);

        if(this.$el.length) {
            this._onScroll();
            this._bindEvents();
        }
    }

    _bindEvents() {
        this.onScrollFn = this._onScroll.bind(this);

        window.addEventListener('scroll', this.onScrollFn);
    }

    _onScroll() {
        this.lastScrollPosition = window.pageYOffset;

        if(!this.isTicking) {
            this.isTicking = true;

            window.requestAnimationFrame(this.updateFn);
        }
    }

    _update() {
        this.isTicking = false;

        if(!this.isSticky && this.lastScrollPosition >= this.stickyOffset) {
            this.$el[0].classList.add('site-header--sticky');
            this.isSticky = true;
        }
        else if(this.isSticky && this.lastScrollPosition < this.stickyOffset) {
            this.$el[0].classList.remove('site-header--sticky');
            this.isSticky = false;
        }
    }
}

export default SiteHeader;
