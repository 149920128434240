import $ from 'jquery';

/* Accordéons (FAQ et corps de texte) */
$('.bloc-single').on('click', function() {
    var $this = $(this)
    var $boxes = $('.bloc-content__container');    

    $boxes.each(function() {
        if($this.attr('bloc-title') === $(this).attr('bloc-title')) {
            $('.bloc-single.active').removeClass('active');
            $this.addClass('active');
            $('.bloc-content__container.active').removeClass('active');
            $(this).addClass('active');
        }
    });
});
