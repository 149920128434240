import Flickity from "flickity";
import "flickity-imagesloaded";
import "flickity-as-nav-for";
import "flickity-fade";

class HomeSlider {
    constructor($el) {
        this.$el = $el;

        this.options = {
            wrapAround: true,
            prevNextButtons: true,
            pageDots: true,
            imageLoaded: true,
            cellSelector: '.homepage-slider__slide',
            fade: true,
        };

        if(this.$el.length) {
            this._init();
        }
    }

    _init() {
        this.$slides = this.$el.find('.homepage-slider__slide');
        this.slidesCount = this.$slides.length;

        if(this.slidesCount > 0) {
            this._createSlider();
        }
    }

    _createSlider() {
        this.slider = new Flickity(this.$el[0], this.options);
    }
}

export default HomeSlider;