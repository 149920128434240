import $ from 'jquery';
import SiteHeader from "./components/site-header";
import DeviceManager from "./utils/device-manager";
import HomeSlider from "./components/home-slider";
import ReassurancesSlider from "./components/reassurances-slider";

import './components/blocs';
import './components/handheld-menu';

$(function () {

	const bootstrapApp = () => {
		const $html = $("html");

		// Touch device detection
		if (DeviceManager.isHandheld()) {
			$html.addClass("touch");

			if (DeviceManager.isIos()) {
				$html.addClass("ios");
			}
		} else {
			$html.addClass("no-touch");
		}

		// IE detection
		if (DeviceManager.isIe11()) {
			$html.addClass("ie11");
		} else if (DeviceManager.isEdge()) {
			$html.addClass("edge");
		}
	}
	
	const instanciateComponents = () => {
		// General components
		const siteHeader = new SiteHeader($(".site-header"), 20);

		// Home slider component
		const $homesSliders = $(".homepage-slider");
		if ($homesSliders.length) {
			$homesSliders.each(function (index, el) {
				const homeSlider = new HomeSlider($(el));
			});
		}

		
	}

	bootstrapApp();
	instanciateComponents();

});

